import classNames from "classnames";
import React, { ReactNode } from "react";

const Tile = ({
  children,
  className,
  onClickHandler,
}: {
  children: ReactNode;
  className?: string;
  onClickHandler?: () => void;
}) => {
  const tileClasses = classNames(
    "card p-3 bg-smooth-primary-100 border border-smooth-neutral-variant-95 shadow-smooth-tile w-36 h-36 rounded-2xl cursor-pointer",
    className
  );
  return (
    <div className={tileClasses} onClick={onClickHandler}>
      <div className="card-body p-0 justify-center">{children}</div>
    </div>
  );
};

export default Tile;
