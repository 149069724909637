import { IRanch } from "../models/ranch";
import { IRoute } from "../models/rover";
import { IUser } from "../models/user";
import { Axios } from "./core";

const BASE_PATH = "/ranch";

export type RanchResponse = {
  data: { routes?: IRoute[]; ranch?: IRanch; message?: string };
};

export const createRanch = async (
  payload: Partial<IRanch>
): Promise<RanchResponse> => await Axios.post(BASE_PATH, payload);

export const updateRanch = async (
  ranchID: string,
  payload: Partial<IRanch>
): Promise<RanchResponse> =>
  await Axios.patch(`${BASE_PATH}/${ranchID}`, payload);

export const deleteRanch = async (ranchID: string): Promise<void> =>
  await Axios.delete(`${BASE_PATH}/${ranchID}`);

export const getRanchByID = async (ranchID: string): Promise<RanchResponse> =>
  await Axios.get(`${BASE_PATH}/${ranchID}`);

export const getRanchByUserID = async (
  userID: string
): Promise<RanchResponse> => await Axios.get(`${BASE_PATH}/users/${userID}`);

export const addUserToRanch = async (
  ranchID: string,
  userID: string
): Promise<RanchResponse> =>
  await Axios.put(`${BASE_PATH}/${ranchID}/users/${userID}`);

export const removeUserFromRanch = async (
  ranchID: string,
  userID: string
): Promise<RanchResponse> =>
  await Axios.delete(`${BASE_PATH}/${ranchID}/users/${userID}`);

export const getRanchUsers = async (ranchID: string): Promise<IUser[]> =>
  await Axios.get(`${BASE_PATH}/${ranchID}/users`);

export const getRanchRoutes = async (ranchID: string): Promise<RanchResponse> =>
  await Axios.get(`${BASE_PATH}/${ranchID}/routes`);

export const deleteRanchRoutes = async (
  ranchID: string
): Promise<RanchResponse> =>
  await Axios.delete(`${BASE_PATH}/${ranchID}/routes`);
